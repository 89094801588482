<template>
  <div>
    <v-flex xs12 py-7 py-sm-0 class="hidden-sm-and-up ftColor">
      <v-layout wrap justify-center pt-2>
        <v-flex xs12 md2>
          <v-layout wrap justify-space-around justify-center text-center>
            <v-flex shrink>
              <a href="mailto:customercare@slots.com.kw" target="blank">
                <v-icon> mdi-email </v-icon>
              </a>
            </v-flex>
            <v-flex shrink>
              <a
                href="https://www.instagram.com/slotsco/?igshid=MzRlODBiNWFlZA%3D%3D"
                target="blank"
              >
                <v-icon> mdi-instagram </v-icon>
              </a>
            </v-flex>
            <v-flex shrink>
              <a
                href="https://www.linkedin.com/company/slotskw/"
                target="blank"
              >
                <v-icon> mdi-linkedin </v-icon>
              </a>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-layout wrap justify-center py-sm-16 py-3 class="ftColor">
      <v-flex sm10>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 md8>
            <v-layout justify-center wrap class="hidden-md-and-up">
              <v-flex xs4 pl-3>
                <v-layout wrap justify-space-around text-left class="text">
                  <v-flex sm6 align-self-center>
                    <router-link :to="'/products'"> Products </router-link>
                  </v-flex>
                  <v-flex sm6 align-self-center>
                    <router-link :to="'/demobooking'"> Onboarding </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4 align-self-center>
                <router-link to="/">
                  <v-img
                    height="30px"
                    class="ml-0"
                    contain
                    src="../../assets/Images/logo.png"
                  >
                  </v-img>
                </router-link>
              </v-flex>

              <v-flex xs4 pr-3>
                <v-layout wrap justify-space-around text-right class="text">
                  <v-flex xs12 sm6 align-self-center>
                    <span @click="navigateToNextPage2()">About Us </span>
                  </v-flex>

                  <v-flex align-self-center sm6>
                    <span style="cursor: pointer" @click="navigateToNextPage()"
                      >Contact us
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-space-around
              text-center
              class="hidden-sm-and-down"
            >
              <v-flex xs12 sm5 align-self-center>
                <v-layout wrap justify-space-around text-center class="text">
                  <v-flex align-self-center xs4 sm4>
                    <router-link :to="'/products'"> Products </router-link>
                  </v-flex>
                  <v-flex align-self-center xs4>
                    <router-link :to="'/demobooking'"> Onboarding </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs2 class="hidden-sm-and-down">
                <router-link to="/">
                  <v-img
                    height="30px"
                    class="ml-0"
                    contain
                    src="../../assets/Images/logo.png"
                  >
                  </v-img>
                </router-link>
              </v-flex>
              <v-flex xs12 sm5 align-self-center>
                <v-layout wrap justify-space-around text-center class="text">
                  <v-flex align-self-center xs4>
                    <router-link :to="'/aboutus'"> About Us </router-link>
                  </v-flex>
                  <!-- <v-flex align-self-center xs4> Career </v-flex> -->
                  <v-flex align-self-center xs4>
                    <!-- <a href="/#/aboutus"> Contact us </a>
                     -->
                    <span style="cursor: pointer" @click="navigateToNextPage()"
                      >Contact us
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pt-lg-12 pt-7 pb-3 pb-sm-0>
            <v-layout wrap>
              <v-flex pl-sm-0 xs12 text-center pb-sm-3 class="text">
                © 2023, All Rights Reserved
              </v-flex>

              <v-flex xs12 py-7 py-sm-0 class="hidden-xs-only">
                <v-layout wrap justify-center pt-2>
                  <v-flex xs12 md2>
                    <v-layout
                      wrap
                      justify-space-around
                      justify-center
                      text-center
                    >
                      <v-flex shrink>
                        <a
                          href="mailto:customercare@slots.com.kw"
                          target="blank"
                        >
                          <v-icon> mdi-email </v-icon>
                        </a>
                      </v-flex>
                      <v-flex shrink>
                        <a
                          href="https://www.instagram.com/slotsco/?igshid=MzRlODBiNWFlZA%3D%3D"
                          target="blank"
                        >
                          <v-icon> mdi-instagram </v-icon>
                        </a>
                      </v-flex>
                      <v-flex shrink>
                        <a
                          href="https://www.linkedin.com/company/slotskw/"
                          target="blank"
                        >
                          <v-icon> mdi-linkedin </v-icon>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                pr-2
                pr-sm-0
                xs10
                sm5
                text-right
                class="text hidden-xs-only"
              >
                <!-- Call us at: +965 000 000 -->
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    navigateToNextPage2() {
      if (this.$route.path != "/aboutus") {
        this.$router.push({ path: "/aboutus" });
      } else {
        this.$nextTick(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        });
      }
    },

    navigateToNextPage() {
      // console.log("44",this.$route.fullPath )
      if (this.$route.path != "/aboutus") {
        this.$router.push({ path: "/aboutus" });
        this.$nextTick(() => {
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
          });
        });
      }
      //  else{
      //    this.$nextTick(() => {
      //     window.scrollTo({
      //       top: 0,
      //       behavior: "smooth",
      //     });
      //   });

      // }
    },
  },
};
</script>