var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticStyle:{"z-index":"100000"},attrs:{"flat":"","app":"","color":"white"}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-sm-and-up",attrs:{"variant":"text"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-layout',{staticClass:"hidden-xs-only",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"lg11":"","px-lg-14":""}},[_c('v-layout',[_c('v-flex',{attrs:{"sm3":"","text-left":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":"","align-start":""}},[_c('v-flex',{attrs:{"xs3":"","lg4":"","shrink":"","align-start":""}},[_c('a',{attrs:{"href":"/"}},[_c('v-img',{staticClass:"ml-0",staticStyle:{"margin":"0 !important"},attrs:{"height":"60px","contain":"","src":require("../../assets/Images/logo-2.png")}})],1)])],1)],1),_c('v-flex',{attrs:{"sm6":"","align-self-center":""}},[_c('v-layout',{staticClass:"text",attrs:{"wrap":"","justify-space-around":"","text-center":""}},[_c('v-flex',{staticClass:"content",attrs:{"align-self-center":"","xs3":""}},[_c('router-link',{attrs:{"to":'/'}},[_c('span',{style:(_vm.$route.path == '/' ? 'color: #004da9' : 'color: #707070')},[_vm._v(" Home")])])],1),_c('v-flex',{staticClass:"content",attrs:{"align-self-center":"","xs3":""}},[_c('router-link',{attrs:{"to":'/aboutus'}},[_c('span',{style:(_vm.$route.path == '/aboutus'
                        ? 'color: #004da9'
                        : 'color: #707070')},[_vm._v(" About Us")])])],1),_c('v-flex',{staticClass:"content",attrs:{"align-self-center":"","xs3":""}},[_c('router-link',{attrs:{"to":'/products'}},[_c('span',{style:(_vm.$route.path == '/products'
                        ? 'color: #004da9'
                        : 'color: #707070')},[_vm._v(" Products ")])])],1),_c('v-flex',{staticClass:"content",attrs:{"align-self-center":"","xs3":""}},[_c('router-link',{attrs:{"to":'/prices'}},[_c('span',{style:(_vm.$route.path == '/prices'
                        ? 'color: #004da9'
                        : 'color: #707070')},[_vm._v(" Prices ")])])],1)],1)],1),_c('v-flex',{attrs:{"sm3":"","text-right":"","align-self-center":""}},[_c('v-btn',{staticClass:"bookBTn",attrs:{"color":"primary","outlined":"","dark":"","rounded":""},on:{"click":function($event){return _vm.navigateToNextPage()}}},[_vm._v(" Get In Touch ")])],1)],1)],1)],1)],1),_c('v-navigation-drawer',{staticClass:"hiddensm-and-up",staticStyle:{"z-index":"1000"},attrs:{"floating":"","width":"100%","height":"100vh","fixed":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-layout',{staticStyle:{"background-color":"white"},attrs:{"wrap":""}},[_c('v-flex',{staticClass:"text",attrs:{"xs12":""}},[_c('v-list',{attrs:{"density":"compact","nav":""}},[_c('a',{attrs:{"href":"/"}},[_c('v-list-item',{attrs:{"prepend-icon":"","title":"Home","value":"home"}},[_c('v-icon',[_vm._v(" mdi-view-dashboard ")]),_vm._v("   Home ")],1)],1),_c('a',{attrs:{"href":"/#/aboutus"}},[_c('v-list-item',{attrs:{"prepend-icon":"","title":"Home","value":"home"}},[_c('v-icon',[_vm._v(" mdi-home-city ")]),_vm._v("   About Us ")],1)],1),_c('a',{attrs:{"href":"/#/products"}},[_c('v-list-item',{attrs:{"prepend-icon":"","title":"Home","value":"home"}},[_c('v-icon',[_vm._v(" mdi-home-city ")]),_vm._v("   Product ")],1)],1),_c('v-list-item',{attrs:{"prepend-icon":"","title":"Home","value":"home"}},[_c('v-icon',[_vm._v(" mdi-file ")]),_vm._v("   Prices ")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }