<template>
  <div>
    <v-app-bar flat app style="z-index: 100000" color="white">
      <v-layout wrap>
        <v-flex shrink>
          <a href="/">
            <v-img width="100px" contain src="../../assets/Images/logo-2.png">
            </v-img>
          </a>
        </v-flex>
      </v-layout>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        color="#004DA9"
        large
        v-if="!drawer"
        class="hidden-sm-and-up"
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-icon color="#004DA9" v-if="drawer"> mdi-close </v-icon>
    </v-app-bar>

    <v-navigation-drawer
      class="hiddensm-and-up"
      floating
      width="100%"
      height="100%"
      fixed
      style="z-index: 1000"
      v-model="drawer"
    >
      <v-layout wrap fill-height>
        <v-flex xs12 style="margin-top: 80px">
          <v-layout px-5 wrap class="text">
            <v-flex xs12>
              <a href="/"> HOME </a>
            </v-flex>

            <!-- <v-flex xs12 align-self-center pt-2> 
             <v-divider></v-divider>
            </v-flex> -->
            <v-flex xs12 pt-5>
          
                 <router-link :to="'/aboutus'">
              
               ABOUT US 
                 </router-link>

               
            
            </v-flex>
            <v-flex xs12 py-5>
            
                <router-link :to="'/products'">
               PRODUCTS
                </router-link>
               
              
            </v-flex>
            <v-flex xs12 pb-5>
                   <router-link :to="'/aboutus'">
              
               CONTACT US
                   </router-link>

               
            
            </v-flex>
            <v-flex xs12 pb-5>
             
               <router-link :to="'/demobooking'">
               BOOK DEMO
               </router-link>
               
             
            </v-flex>
            <v-flex xs12>
              <router-link :to="'/prices'"> PRICES </router-link>
            </v-flex>
          </v-layout>
        </v-flex>

      
        <v-flex xs12 align-self-end>
          <v-layout
            wrap
            py-5
            justify-space-around
            justify-center
            text-center
            style="background-color: white"
          >
            <v-flex>
              <a href="mailto:customercare@slots.com.kw" target="blank">
                <v-icon> mdi-email </v-icon>
              </a>
            </v-flex>
            <v-flex>
              <a
                href="https://www.instagram.com/slotsco/?igshid=MzRlODBiNWFlZA%3D%3D"
                target="blank"
              >
                <v-icon> mdi-instagram </v-icon>
              </a>
            </v-flex>
            <v-flex>
              <a
                href="https://www.linkedin.com/company/slotskw/"
                target="blank"
              >
                <v-icon> mdi-linkedin </v-icon>
              </a>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  mounted() {},
  methods: {
    goto(val) {
      // this.$router.go();
      this.$router.push(val);
    },
  },
};
</script>
<style scoped>
.fx {
  position: fixed;
}
.content {
  transition-duration: 0.3s;
  transition-timing-function: linear;
}

.content:hover {
  font-size: 18px;
  /* font-weight:600; */
  transition: 0.3s;
  color: #004da9;
}
</style>