import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

function lazyLoad(view){
  return() => import(`@/components/${view}.vue`)
}
let router = new Router({
  mode: "history",
  // base: process.env.BASE_URL,
  base: '/',
  routes: [
    {
      path: "/",
      name: 'Home',
      props: true,
      // component: () => import("./components/Home/HomE"),
      component: lazyLoad('Home/HomE'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/aboutus",
      name: 'About Us',
      props: true,
      // component: () => import("./components/AboutUs/AboutUs"),
      component: lazyLoad('AboutUs/AboutUs'),
      meta: {
        requiresAuth: false,
        disAllow: true,
      },
    },
    {
      path: "/products",
      name: 'Products',
      props: true,
      // component: () => import("./components/Products/ProductS"),
      component: lazyLoad('Products/ProductS'),

      meta: {
        requiresAuth: false,

      },
    },
    {
      path: "/demobooking",
      name: 'Demo Booking',

      props: true,
      // component: () => import("./components/BookADemo/BookaDemo"),
      component: lazyLoad('BookADemo/BookaDemo'),

      meta: {
        requiresAuth: false,

      },
    },
    {
      path: "/Prices",
      name: 'Prices',

      props: true,
      // component: () => import("./components/Common/ComingSoon"),
      component: lazyLoad('Common/ComingSoon'),

      meta: {
        requiresAuth: false,

      },
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});

router.beforeEach((to, from, next) => {

  document.title = to.meta.title || "Slots | " + to.name;
  next();


  // gsap.killAll(); // Unregi
  // localStorage.setItem("LS_ROUTE_KEY", from.path);
  // if (localStorage.getItem("LS_ROUTE_KEY") == "ninte pagente peer" && to.meta.disAllow == true) {
  //   return router.push('/page3')

  // }


  // next()
})


export default router;
