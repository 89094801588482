import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios';

import VueResizeText from 'vue-resize-text';

Vue.use(VueResizeText)

import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)
// import VueParallaxJs from 'vue-parallax-js';
// import { ObServer } from "gsap/ObserverPlugin";
// Vue.component('ObServer', ObServer)


// import { Observer } from "gsap/ObserverPlugin";
// Vue.component('ObServer', Observer)


// Vue.use(VueParallaxJs);

Vue.config.productionTip = false;
axios.defaults.baseURL = 'https://api-crm.slots.systems/';
Vue.prototype.baseURL = "https://api-crm.slots.systems/"



new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
