<template>
  <v-app>
    <Haeder v-if="$vuetify.breakpoint.name != 'xs'" />
    <Haederxs v-if="$vuetify.breakpoint.name == 'xs'" />

    <v-main>
      <router-view> > </router-view>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
// import HelloWorld from "./components/Home/HomE";
import Haeder from "./components/Common/HeadeR";
import Haederxs from "./components/Common/HeaderXs";

import Footer from "./components/Common/FooteR";

import "./assets/StyleS.css";
export default {
  name: "App",

  components: {
    // HelloWorld,
    Haederxs,
    Haeder,
    Footer,
  },
  //  data() {
  //   return {
  //     items: [], // Your web page content items
  //     scrollPosition: 0,
  //     lastScrollPosition: 0,
  //   };
  // },
  // mounted() {
  //   window.addEventListener('scroll', this.handleScroll);
  //   console.log("gvgvgvh")
  // },
  // beforeDestroy() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
  // methods: {
  //   handleScroll() {
  //     this.lastScrollPosition = this.scrollPosition;
  //     this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  //   },
  // },
};
</script>
